import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// Core Components

function Header5() {
  return (
    <>
      <header className="header-5">
        <div className="page-header">
          <Container>
            <Row className="align-items-center text-left">
              <Col lg="5" xs="12">
                <h1 className="display-3">
                  Endorsed Democrat
                  <span className="text-primary">
                    Vote <strong>Virginia Tallent</strong> for Hamilton County
                    Court of Common Pleas
                  </span>
                </h1>
                <p className="lead pb-4">
                  As an experienced public servant, lawyer and leader, my
                  mission is clear – to champion fairness and efficiency in our
                  courthouse for the betterment of our community.
                </p>
                <Row className="row-input">
                  <Col sm="1"></Col>
                  <Col className="pl-lg-0" sm="4" xs="12">
                    <Button
                      block
                      color="warning"
                      href="https://secure.actblue.com/donate/tallent-for-judge"
                    >
                      Contribute
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col className="pl-0" lg="7" xs="12">
                <img
                  alt="..."
                  className="ml-lg-5"
                  src={require("assets/img/ill/1700924062496.jpg")}
                  width="100%"
                ></img>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
    </>
  );
}

export default Header5;
