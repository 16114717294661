import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "assets/css/nucleo-svg.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-design-system.scss?v1.0.0";

import MeetCandidate from "views/examples/MeetCandidate.js";
import LandingPage from "views/examples/LandingPage.js";
// import GetInvolved from "views/examples/GetInvolved.js";
import Error from "views/examples/Error.js";
import Error500 from "views/examples/Error500.js";
import Endorsements from "views/examples/Endorsements";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" exact element={<LandingPage />} />
      <Route path="/meet-virginia-tallent" exact element={<MeetCandidate />} />
      {/* <Route path="/get-involved" exact element={<GetInvolved />} /> */}
      <Route path="/endorsements" exact element={<Endorsements />} />
      <Route path="/error" exact element={<Error />} />
      <Route path="/error-500" exact element={<Error500 />} />
      <Route path="/" element={<Navigate to="/" replace />} />
    </Routes>
  </BrowserRouter>
);
